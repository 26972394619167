import fetch from 'auth/FetchMasterInterceptor'
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';


const taskService = {}
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;


taskService.getTask = function (data) {
    return fetch({
      url: '/task/list',
      method: 'post',
      data: data
    })
  }
  
  taskService.addTasks = function (data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${jwtToken}`,
      },
    };
    return axios
      .post(`${API_BASE_URL}/task/add`, data, config);
  }
  
  
  taskService.editTasks = function (data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${jwtToken}`,
      },
    };
    return axios
      .post(`${API_BASE_URL}/task/update`, data, config);
  }
  
  
  taskService.deleteTask = function (data) {
    return fetch({
      url: '/task/delete',
      method: 'post',
      data: data
    })
  }

  taskService.ShowTask = function (data) {
    return fetch({
      url: '/task/show',
      method: 'post',
      data: data
    })
  }
//status 
taskService.getStatus=function(data){
    return fetch({
        url:"/status/list",
        method:"POST",
        data:data,
    })
}

taskService.getStatUpdate=function(data){
  return fetch({
      url:"/task/update-status",
      method:"POST",
      data:data,
  })
}
taskService.getPriorty=function(data){
    return fetch({
        url:"/priority/list",
        method:"POST",
        data:data,
    })
}
taskService.getCategory=function(data){
    return fetch({
        url:"/category/list",
        method:"POST",
        data:data,
    })
}

taskService.getUser=function(data){
    return fetch({
        url:"/user/list",
        method:"POST",
        data:data
    })
}
export default taskService