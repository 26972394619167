import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';
import SettingService from 'services/SettingService';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const userListAll = createAsyncThunk('/user/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  SettingService.getUser()
        //  console.log(response.data)
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
			
		userList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(userListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(userListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	userList,
    
    
} = userSlice.actions

export default userSlice.reducer