import fetch from 'auth/FetchMasterInterceptor'

const dashboardService = {}


dashboardService.getDashboard = function (data) {
  return fetch({
    url: '/dashboard-data',
    method: 'post',
    data:data
  })
}

dashboardService.getDashboardDataSection = function (data) {
  return fetch({
    url: '/dashboard-data-section',
    method: 'post',
    data:data
  })
}

dashboardService.getGraph = function (data) {
  return fetch({
    url: '/graph-data',
    method: 'post',
    data:data
  })
}
dashboardService.getCosumpution = function (data) {
  return fetch({
    url: '/power-consumption',
    method: 'post',
    data:data
  })
}
export default dashboardService