import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';
import dashboardService from 'services/Dashboard';





export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const graphListAll = createAsyncThunk('/graph-data',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  dashboardService.getGraph()
        
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const graphSlice = createSlice({
	name: 'graph',
	initialState,
	reducers: {
			
		graphlist: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(graphListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(graphListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	loglist,
    
    
} = graphSlice.actions

export default graphSlice.reducer