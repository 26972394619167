import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';
import taskService from 'services/TasksServices';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const taskListAll = createAsyncThunk('/task/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  taskService.getTask()
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const taskSlice = createSlice({
	name: 'task',
	initialState,
	reducers: {
			
		taskList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(taskListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(taskListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	taskList,
    
    
} = taskSlice.actions

export default taskSlice.reducer