import { defaultConfig } from 'antd/es/theme/internal'
import fetch from 'auth/FetchMasterInterceptor'

const AlarmServices={}

 AlarmServices.getAlarm=function(data){
    return fetch({
  url:'get-alarm-list',
  method:"POST",
  data:data
    })
}



export default AlarmServices;