import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

// import { useDispatch } from 'react-redux';
import SettingService from 'services/SettingService';
import dashboardService from 'services/Dashboard';





export const initialState = {
	loading: false,
	message: '',
	list:null,
	dloading:false,
	dlist:null
}



export const settingListAll = createAsyncThunk('setting/setting-list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  SettingService.listSetting()
	
		return response;
	} catch (err) {
		
		return rejectWithValue(err.response?.message || 'Error')
	}
})

export const dashboardListAll = createAsyncThunk('/dashboard-data',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  dashboardService.getDashboard(data)
        
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const settingSlice = createSlice({
	name: 'setting',
	initialState,
	reducers: {
			
		settingList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(settingListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(settingListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})

			.addCase(dashboardListAll.pending, (state) => {
		
				state.dloading = false
			})
			.addCase(dashboardListAll.fulfilled, (state, action) => {
				state.dloading = true
				state.dlist = action.payload
			})
			
	},
})

export const { 
	settingList,
    
    
} = settingSlice.actions

export default settingSlice.reducer