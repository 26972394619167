import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';
import dashboardService from 'services/Dashboard';

import logservices from 'services/LogDetails';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}

export const dashboardListAll = createAsyncThunk('/dashboard-data',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  dashboardService.getDashboard(data)
        
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
			
		loglist: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(dashboardListAll.pending, (state) => {
				state.loading = false
			})
			.addCase(dashboardListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	loglist,
    
    
} = dashboardSlice.actions

export default dashboardSlice.reducer