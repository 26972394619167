import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';

import logservices from 'services/LogDetails';
import masterService from 'services/MasterService';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const meterListAll = createAsyncThunk('/meter/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  masterService.getMeter()
        console.log(response)
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const meterSlice = createSlice({
	name: 'meter',
	initialState,
	reducers: {
			
		loglist: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(meterListAll.pending, (state) => {
				state.loading = false
			})
			.addCase(meterListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	loglist,
    
    
} = meterSlice.actions

export default meterSlice.reducer