import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
export const PriorityListAll = createAsyncThunk('/priority/list',async (data, { rejectWithValue }) => {
    try {
        const response = await masterService.getPriorty(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const PrioritySlice = createSlice({
    name: 'Priority',
    initialState,
    reducers: {
        PriorityListData: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(PriorityListAll.pending, (state) => {
                state.loading = false
            })
            .addCase(PriorityListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
    },
})
export const {
    PriorityListData,
} = PrioritySlice.actions
export default PrioritySlice.reducer