import fetch from 'auth/FetchMasterInterceptor'

const logservices={}

logservices.getlogdetail=function(data){
    return fetch({
        url:"/user-activities",
        method:'POST',
        data:"data"
    })
}

export default logservices;