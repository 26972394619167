import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';


import masterService from 'services/MasterService';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const syncmateListAll = createAsyncThunk('/raspberry/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  masterService.getRaspberry()
        console.log(response)
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const syncmateSlice = createSlice({
	name: 'syncmate',
	initialState,
	reducers: {
			
	syncmatelist: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(syncmateListAll.pending, (state) => {
				state.loading = false
			})
			.addCase(syncmateListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	syncmatelist,
    
    
} = syncmateSlice.actions

export default syncmateSlice.reducer