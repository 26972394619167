import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';

import { useDispatch } from 'react-redux';
import AlarmServices from 'services/AlarmServices';




export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const alarmListAll = createAsyncThunk('get-alarm-list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await  AlarmServices.getAlarm()
		return response.data;
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const alarmSlice = createSlice({
	name: 'alarm',
	initialState,
	reducers: {
			
		alarmList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(alarmListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(alarmListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	alarmList,
    
    
} = alarmSlice.actions

export default alarmSlice.reducer